import "core-js/modules/es.array.push.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "notices"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = {
  class: "isItems"
};
const _hoisted_4 = {
  class: "titles"
};
const _hoisted_5 = ["innerHTML"];
const _hoisted_6 = {
  class: "time"
};
import { router } from '@/router';
import { computed, ref } from 'vue';
import { getNotices } from '@/http';
import { useUserStore } from '@/stores/user';
export default {
  __name: 'notices',
  setup(__props) {
    const userStore = useUserStore();
    const lang = computed(() => userStore.lang);
    const titleKey = computed(() => {
      return {
        'en_US': 'name',
        'fr_FR': 'fr_FR_name',
        'es_ES': 'es_ES_name',
        'it_IT': 'it_IT_name',
        'vi': 'vt_VT_name'
      }[lang.value];
    });
    const contentKey = computed(() => {
      return {
        'en_US': 'content',
        'fr_FR': 'fr_FR_content',
        'es_ES': 'es_ES_content',
        'it_IT': 'it_IT_content',
        'vi': 'vt_VT_content'
      }[lang.value];
    });
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const page = ref(1);
    const back = () => {
      router.go(-1);
    };
    const jumpTo = (path, item) => {
      userStore.setNotice(item);
      router.push(path);
    };
    const onLoad = async () => {
      loading.value = true;
      getNotices({
        page: page.value,
        perPage: 10
      }).then(({
        data
      }) => {
        loading.value = false;
        if (data.current_page === 1) {
          list.value = data.data;
        } else {
          list.value = list.value.concat(data.data);
        }
        finished.value = data.current_page >= data.last_page;
        if (!finished.value) {
          page.value++;
        }
      });
    };
    return (_ctx, _cache) => {
      const _component_van_cell = _resolveComponent("van-cell");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('announcement')), 1), _createVNode(_component_van_list, {
        loading: loading.value,
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => loading.value = $event),
        finished: finished.value,
        "finished-text": _ctx.$t('no_more'),
        onLoad: onLoad
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, (item, index) => {
          return _openBlock(), _createBlock(_component_van_cell, {
            border: false,
            key: item.id,
            onClick: $event => jumpTo('/noticeDetails', item)
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(item[titleKey.value]), 1), _createElementVNode("div", {
              class: "content",
              innerHTML: item[contentKey.value]
            }, null, 8, _hoisted_5), _createElementVNode("div", _hoisted_6, _toDisplayString(item.created_at), 1)])]),
            _: 2
          }, 1032, ["onClick"]);
        }), 128))]),
        _: 1
      }, 8, ["loading", "finished", "finished-text"])]);
    };
  }
};